<template>
  <el-form label-width="180px">
    <el-form-item label="分销层次:">
      <el-radio-group v-model="info.tier" @change="updateTier">
        <el-radio :label="0">不开启</el-radio>
        <el-radio :label="1">一级分销</el-radio>
        <el-radio :label="2">二级分销</el-radio>
        <el-radio :label="3">三级分销</el-radio>
      </el-radio-group>
      <p style="line-height: 20px; font-size: 12px">请在等级设置中，设置佣金比例和升级条件</p>
    </el-form-item>
    <el-form-item label="分销内购:">
      <el-radio-group v-model="info.in_purchasing">
        <el-radio :label="0">不开启</el-radio>
        <el-radio :label="1">开启</el-radio>
      </el-radio-group>
      <p style="line-height: 20px; font-size: 12px">开启分销内购，分销商自己购买商品，享受一级佣金（直接佣金 - 最高级别佣金），其上级享受二级佣金（间接佣金），上上级享受三级佣金（次级佣金）</p>
    </el-form-item>
    <el-form-item label="分销员用户关系：">
      <el-radio-group v-model="info.is_binding">
        <el-radio :label="0">不绑定</el-radio>
        <el-radio :label="1">绑定</el-radio>
      </el-radio-group>
      <p style="line-height: 20px; font-size: 12px">不绑定：用户通过该分销员分享的商品购买后，不绑定关系，只有再通过分销员分享链接购买商品，分销员才有佣金</p>
      <p style="line-height: 20px; font-size: 12px">绑定：用户点击分销员分销的商品链接后，即绑定关系，后续用户再平台购买全部商品，都为此分销员佣金</p>
    </el-form-item>
    <el-form-item label="成为分销商条件:">
      <el-radio-group v-model="info.term" @change="info.goods_name = ''">
        <el-radio :label="1">申请（需要审核）</el-radio>
        <el-radio :label="2">申请（不需要审核）</el-radio>
        <el-radio :label="3">消费金额</el-radio>
        <el-radio :label="4">购买商品</el-radio>
      </el-radio-group>
      <div class="money" v-if="info.term == 3">
        消费达到&nbsp;&nbsp;&nbsp;
        <el-input v-model="info.money"></el-input>
        &nbsp;&nbsp;元&nbsp;&nbsp;&nbsp;&nbsp;订单支付则满足条件
      </div>
      <div class="condition" v-if="info.term == 1 || info.term == 2">
        <template v-if="info.term == 1">
          <el-radio-group v-model="info.is_pay">
            <el-radio :label="0">不需付费</el-radio>
            <el-radio :label="1">付费</el-radio>
          </el-radio-group>
          <el-form-item v-if="info.is_pay" label="付款金额：" label-width="90px">
            <el-input style="width: 300px" v-model="info.pay_amount"></el-input>
            元
          </el-form-item>
        </template>
        <div>
          <span>手机号（用户必须填项）</span>
          <el-button type="text" @click="handleshowAddInfo">{{ selectInfoList.length ? `已添加${selectInfoList.length}个` : '添加' }}</el-button>
        </div>
        <div class="opRequired">
          <span>必填项</span>
          <div class="opRequired-box">
            <div v-for="(item, index) in info.opRequired" :key="index">
              <el-checkbox :label="item.name" v-model="item.is_select" true-label="1" false-label="0" @change="changeRequired(index)"></el-checkbox>
              <i class="el-icon-circle-close" v-if="item.is_custom == 1" @click="removeOpRequired(index)"></i>
            </div>
          </div>
        </div>
        <div class="optional">
          <span>选填项</span>
          <div class="optional-box">
            <div v-for="(item, index) in info.optional" :key="index">
              <el-checkbox :label="item.name" v-model="item.is_select" true-label="1" false-label="0" @change="changeOptional(index)"></el-checkbox>
              <i class="el-icon-circle-close" v-if="item.is_custom == 1" @click="removeOptional(index)"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="buy" v-if="info.term == 4">
        <el-input v-model="info.goods_name" disabled>
          <el-button slot="append" @click="showSelectGoods = !0">选择商品</el-button>
        </el-input>
        &nbsp;&nbsp;&nbsp;&nbsp;订单支付则满足条件
      </div>
    </el-form-item>
    <el-form-item label="是否需要签署电子协议：">
      <el-switch v-model="info.is_electronic_agreement" :active-value="1" :inactive-value="0"></el-switch>
      <!-- <el-upload
        v-if="info.is_electronic_agreement"
        class="upload-demo"
        ref="upload"
        :action="uploadPath"
        :headers="headers"
        :before-upload="onFileBeforeUpload"
        :on-success="onFileSuccess"
        :limit="1"
        :file-list="fileList"
        :auto-upload="true"
      >
        <el-button slot="trigger" size="small" type="primary">上传文件</el-button>
        <div slot="tip" class="el-upload__tip">只能上传html文件</div>
      </el-upload> -->
    </el-form-item>
    <el-form-item label="是否有推广业绩任务：">
      <el-switch v-model="info.is_task" :active-value="1" :inactive-value="0"></el-switch>
      <template v-if="info.is_task">
        <el-form-item label="任务额度：" label-width="90px">
          <el-input v-model="info.task_value.value"></el-input>
        </el-form-item>
        <el-form-item label="任务不达标处理方法：" label-width="160px">
          <el-radio-group v-model="info.task_value.is_clear">
            <el-radio :label="0">不清退</el-radio>
            <el-radio :label="1">清退</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="清退日期每月：" label-width="120px">
          <el-select style="width: 100%" v-model="info.task_value.date" filterable placeholder="请选择扣款日期，扣款时间为当日24:00:00，即第二日凌晨00:00:00">
            <el-option v-for="date in 28" :key="date" :label="date" :value="date"></el-option>
          </el-select>
        </el-form-item>
      </template>
    </el-form-item>
    <el-dialog title="申请条件添加" :visible.sync="showAddInfo" width="760px" center>
      <div class="info-content">
        <ul class="add-info-list">
          <li class="info-item" v-for="(item, index) in addInfoList" :key="index">
            <el-input v-model="item.name" placeholder="请添加信息"></el-input>
            <span style="margin: 0 10px">必填</span>
            <el-select v-model="item.require" filterable placeholder="请选择">
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
            <span style="margin: 0 10px">类型</span>
            <el-select v-model="item.type" filterable placeholder="请选择">
              <el-option label="文本" :value="1"></el-option>
              <el-option label="图片" :value="2"></el-option>
            </el-select>
          </li>
        </ul>
        <el-button type="text" @click="pushAddInfoList">添加申请信息</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddInfo = !1">取 消</el-button>
        <el-button type="primary" @click="confirmAddInfo">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择商品" :visible.sync="showSelectGoods" width="52%">
      <list-Components v-if="showSelectGoods" :styleMethon="0" @getListItem="getGoodsInfo"></list-Components>
    </el-dialog>
    <Preservation @preservation="saveSet" text="下一步"></Preservation>
  </el-form>
</template>

<script>
import Preservation from '@/components/preservation';
import listComponents from '@/components/listComponents';
import config from '@/util/config';
import cookies from 'vue-cookies';
import _ from 'lodash';
export default {
  components: {
    Preservation,
    listComponents,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    gradeList: {
      tyep: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showSelectGoods: !1,
      showAddInfo: !1,
      selectInfoList: [],
      addInfoList: [],
      goodsInfo: {},
      fileList: [],
      uploadPath: config.baseurl + '/admin/Upload/addElectronicAgreement',
      headers: {
        'access-token': cookies.get('token'),
      },
    };
  },
  created() {},
  methods: {
    updateTier() {
      this.$axios
        .post(this.$api.distribution.editTier, {
          tier: this.info.tier,
        })
        .then(res => {
          if (res.code == 0) {
            console.log(1111111);
          } else {
            this.$message.error(res.$message);
          }
        });
    },
    onFileSuccess() {
      this.$message.success('上传成功');
    },
    onFileBeforeUpload(file) {
      if (file.type != 'text/html') {
        this.$message.warning('请上传正确文件格式！');
        return false;
      }
    },
    saveSet() {
      let info = _.cloneDeep(this.info);
      let data = {
        tier: info.tier,
        in_purchasing: info.in_purchasing,
        term: info.term,
        is_electronic_agreement: info.is_electronic_agreement,
        is_task: info.is_task,
        is_binding:info.is_binding
      };
      if (info.term == 1 || info.term == 2) {
        data.value = {
          opRequired: [],
          optional: [],
        };
        if (info.term == 1) {
          data.value.is_pay = info.is_pay;
          data.value.pay_amount = info.pay_amount;
        }
        for (let i in info.opRequired) {
          data.value.opRequired.push({
            name: info.opRequired[i].name,
            is_select: info.opRequired[i].is_select,
            is_custom: info.opRequired[i].is_custom || 0,
            type: info.opRequired[i].type || 1,
          });
        }
        for (let i in info.optional) {
          data.value.optional.push({
            name: info.optional[i].name,
            is_select: info.optional[i].is_select,
            is_custom: info.optional[i].is_custom || 0,
            type: info.optional[i].type || 1,
          });
        }
      } else if (info.term == 3) {
        data.value = info.money;
      } else {
        if (!info.value || !info.goods_name) return this.$message.warning('请选择商品');
        data.value = info.value;
      }
      if (info.is_task) {
        if (!info.task_value.value) return this.$message.warning('请填写任务额度');
        if (!info.task_value.date) return this.$message.warning('请选择清退日期');
        data.task_value = info.task_value;
      }
      //判断是否设置分销等级
      if (this.gradeList.length) {
        this.$axios.post(this.$api.distribution.editDistribution, data).then(res => {
          if (res.code == 0) {
            this.$emit('updateGradeList');
            this.$message.success('保存成功');
            this.$emit('tabSwich', '2');
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        this.$message.warning('请先设置分销等级');
        this.$emit('tabSwich', '2');
      }
    },
    getGoodsInfo(val) {
      this.showSelectGoods = !1;
      this.info.goods_name = val.goods_name;
      this.info.value = val.id;
      this.goodsInfo = val;
    },
    changeRequired(index) {
      let optional = this.info.optional;
      let currentInfo = this.info.opRequired[index];
      if (currentInfo.is_select) {
        optional.map(item => {
          if (currentInfo.name == item.name && item.is_select) item.is_select = !1;
        });
      }
    },
    changeOptional(index) {
      let opRequired = this.info.opRequired;
      let currentInfo = this.info.optional[index];
      if (currentInfo.is_select) {
        opRequired.map(item => {
          if (currentInfo.name == item.name && item.is_select) item.is_select = !1;
        });
      }
    },
    handleshowAddInfo() {
      this.showAddInfo = !0;
      this.addInfoList = [];
    },
    pushAddInfoList() {
      this.addInfoList.push({
        name: '',
        require: 1,
        type: 1,
      });
    },
    confirmAddInfo() {
      let addInfoList = this.addInfoList;
      for (let i in addInfoList) {
        if (!addInfoList[i].name) return this.$message.warning('请填写信息');
      }
      for (let i in addInfoList) {
        if (addInfoList[i].require) {
          this.info.opRequired.push({
            name: addInfoList[i].name,
            type: addInfoList[i].type,
            require: 1,
            is_must: 0,
            is_select: '1',
            is_custom: 1,
          });
        } else {
          this.info.optional.push({
            name: addInfoList[i].name,
            type: addInfoList[i].type,
            require: 0,
            is_select: '1',
            is_custom: 1,
          });
        }
      }
      this.selectInfoList.push(...addInfoList);
      this.showAddInfo = !1;
    },
    //删除自定义必填项
    removeOpRequired(index) {
      this.info.opRequired.splice(index, 1);
      this.selectInfoList.splice(0, 1);
    },
    //删除自定义选填项
    removeOptional(index) {
      this.info.optional.splice(index, 1);
      this.selectInfoList.splice(0, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.el-form {
  width: 800px;
}
.money {
  margin-top: 10px;
  display: flex;
  .el-input {
    width: 300px;
  }
}
.buy {
  margin-top: 10px;
  .el-input {
    width: 400px;
  }
}
.opRequired,
.optional {
  display: flex;
  align-items: center;
  span {
    margin-right: 15px;
  }
  .opRequired-box,
  .optional-box {
    width: 500px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    div {
      margin-right: 30px;
      position: relative;
      i {
        position: absolute;
        right: -20px;
        top: 0;
        font-size: 18px;
      }
    }
  }
}
.info-content {
  text-align: center;
  .add-info-list {
    .coupon-item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      .el-input {
        width: 100px;
        margin-right: 10px;
      }
      i {
        font-size: 20px;
        margin-left: 20px;
      }
    }
    .info-item {
      margin-bottom: 20px;
      .el-input {
        width: 280px;
      }
      .el-select {
        width: 150px;
      }
    }
  }
}
.upload-demo {
  width: 400px;
}
.el-form-item {
  margin-bottom: 10px;
}
</style>
