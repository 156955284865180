import { render, staticRenderFns } from "./set.vue?vue&type=template&id=4fe9f4dc&scoped=true&"
import script from "./set.vue?vue&type=script&lang=js&"
export * from "./set.vue?vue&type=script&lang=js&"
import style0 from "./set.vue?vue&type=style&index=0&id=4fe9f4dc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fe9f4dc",
  null
  
)

export default component.exports